<template>
  <div class="logo w-1920">
    <div class="logopic w-1200">
      <div class="logopic-left">
        <img src="../../../assets/login/logo.gif" alt="" />
        <div class="about">
          <div class="user">
            {{ msg }}
          </div>
          <!-- <router-link class="shouye" :to="{ name: 'Home' }" tag="div"
              >返回首页</router-link
            > -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    msg: String,
  },
};
</script>
<style lang="scss" scoped>
.logo {
  width: 100%;
  height: 110px;
  border-bottom: 3px solid #f5f5f5;
  .logopic {
    width: 1234px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    &-left {
      display: flex;
      align-items: center;
      img {
        width: 158px;
        height: 44px;
        margin-left: 60px;
      }
      .about {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        .shouye {
          border: 1px solid #245af2;
          height: 14px;
          width: 80px;
          line-height: 14px;
          text-align: center;
          border-radius: 5px;
          cursor: pointer;
          color: #245af2;
          font-weight: 600;
        }
        .user {
          cursor: pointer;
          font-size: 19px;
          color: #2659f0;
          font-weight: 600;
        }
      }
    }
    &-right {
      height: 100px;
      font-size: 15px;
      display: flex;
      align-items: flex-end;
      &_L {
        color: #9a9a9a;
      }
      &_R {
        color: #f14f44;
        margin-left: 17px;
        cursor: pointer;
      }
    }
  }
}
</style>
